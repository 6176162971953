import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../../i18n'
import { translate } from "react-i18next"

import './baseLayout.css'
import './../styles.scss'
import Footer from './../footer/footer'
import Navbar from '../navbar/navbar'
import baseLayout from './baseLayout.css'

const BaseLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}

          // meta={[
          //     { name: 'description', content: 'UGo est la plateforme de covoiturage de l\'Université de Liège. Pour un trajet unique ou régulier, notre système intelligent vous met en relation avec les membres de votre communauté (université / école, institution, entreprise).' },
          //     { name: 'keywords', content: 'UGo, covoiturage, covoituliege, mobilité, smart mobility, environnement, partage, sécurité, université, Liège' },
          //     {name: 'og:title', content: 'UGo - Université de Liège'},
          //     {name: 'og:type', content: 'website'},
          //     {name: 'og:description', content: 'UGo est la plateforme de covoiturage de l\'Université de Liège. Pour un trajet unique ou régulier, notre système intelligent vous met en relation avec les membres de votre communauté (université / école, institution, entreprise).'},
          //     {name: 'og:image', content: {seo}},
          //     {name: 'twitter:site', content: '@covoituliege'},
          //     {name: 'twitter:creator', content: '@covoituliege'},
          //     {name: 'twitter:title', content: 'UGo - Université de Liège'},
          //     {name: 'twitter:description', content: 'UGo est la plateforme de covoiturage de l\'Université de Liège. Pour un trajet unique ou régulier, notre système intelligent vous met en relation avec les membres de votre communauté (université / école, institution, entreprise).'},
          //     {name: 'twitter:card', content: 'summary_large_image'},
          //     {name: 'twitter:image', content: {seo}}
          // ]}

        >
          <html lang="en" />
          <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700" rel="stylesheet"/>
        </Helmet>
        <Navbar/>
        <div class="padding">
          {children}
        </div>
        <Footer/>
      </>
    )}
  />
)

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default translate("all")(BaseLayout)
