import React, { Component } from "react"
import { translate } from "react-i18next"

import formStyles from  './form.module.scss'

class Form extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    "subject": "UGo - Formulaire de contact"
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    })
  };

  handleSubmit = event => {
    event.preventDefault();

    const { t } = this.props;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let s = this;

    window.fetch("/api/feedback/public-form", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(this.state)
    }).then(function(myBlob) {
      if (myBlob.status === 200) {
        // success
          s.setState({
              name: "",
              email: "",
              message: ""
          });

          window.alert(t("success"));
      } else {
        // failed
          window.alert(t("failure"));
      }
    }).catch(function(error) {
      // an error occurred
    });
  };

  render() {
    const { t } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={formStyles.wrapper}>
          <input
            placeholder={t("name")}
            type="text"
            name="name"
            id="name"
            value={this.state.name}
            onChange={this.handleInputChange}
          />
          <input
            placeholder={t("mail")}
            type="email"
            name="email"
            id="email"
            value={this.state.email}
            onChange={this.handleInputChange}
          />
          <textarea
            placeholder={t("message")}
            name="message"
            id="message"
            rows="6"
            value={this.state.message}
            onChange={this.handleInputChange}
          ></textarea>
        </div>
        <div className={formStyles.button}>
          <input type="submit" value={t("send")} className={formStyles.special}/>
        </div>
      </form>
    )
  }
}

export default translate("form")(Form)