import React, { Component } from "react"
import { translate } from "react-i18next"
import LanguageSwitcher from './languageSwitcher'

import footerStyle from './footer.module.scss'
import uliege_white from '../../images/uliege_logo_white.svg'
import ugo_logo_white from '../../images/white_logo_ugo_horizontal.svg'

import * as consts from '../../const'

import { Link } from 'gatsby'

class Footer extends Component {
  render() {
    const { t } = this.props

    return (
      <footer className={footerStyle.footer}>
        <div className={footerStyle.footer_wrapper}>
        <div className={footerStyle.wrapper}>
          <div className={footerStyle.leftlogo}>
            <div className={footerStyle.top}>
              <img src={ugo_logo_white} alt="logo ugo"/>
              <div className={footerStyle.line}></div>
              <img src={uliege_white} alt="logo uliège"/>
            </div>
            <div className={footerStyle.language_wrapper}>
              <div className={footerStyle.language}>
                <label htmlFor="language">{t("language")}</label>
                <LanguageSwitcher/>
              </div>
            </div>
          </div>

          <div className={footerStyle.links}>
            <div>
              <h6>{t("contact")}</h6>
              <a className={footerStyle.mail} href="mailto:ugo@uliege.be">ugo@uliege.be</a>
              <div>
                <span className={footerStyle.location}></span>
                <div className={footerStyle.adress}>
                <p>Université de Liège - SEGI</p>
                <p>Allée de la Découverte 8, Quartier Polytech 1
                  4000 Liège, Belgique</p>
                </div>
              </div>
              <a className={footerStyle.facebook} href="https://www.facebook.com/ugo.covoiturage">UGO - covoiturage</a>
            </div>

            <div>
              <h6>{t("help")}</h6>
              <Link to="/about">{t("about")}</Link>
              <Link to="/how">{t("how")}</Link>
              <Link to="/faq">{t("faq")}</Link>
              <a href={consts.AppStore}>{t("download_ios")}</a>
              <a href={consts.PlayStore}>{t("download_android")}</a>
            </div>

            <div>
              <h6>{t("legal")}</h6>
              <Link to="/privacy">{t("privacy_policy")}</Link>
              <Link to="/terms">{t("terms")}</Link>
            </div>
          </div>
        </div>

        <div className={footerStyle.big_line}></div>
        <p className={footerStyle.copyright}>Copyright © 2020 Université de Liège. {t("all_rights_reserved")}</p>
        </div>
      </footer>
    )
  }
}

export default translate("footer")(Footer)