import React, { Component } from 'react'
import { Link } from 'gatsby'
import { translate } from "react-i18next"

import navStyle from './navbar.module.scss'

import logo_ugo from "../../images/logo_ugo_horizontal.svg"


class NavBar extends Component {

  constructor(props) {
    super(props);
    const { i18n } = this.props
    this.state = {openMenu: false}
  }

  displayMenu = event => {
    let s = this;

    s.setState({
      openMenu: !this.state.openMenu
    })
  }

  render() {
    const { t } = this.props;

    return (

      <div className={navStyle.navigation}>

        <div className={navStyle.menutoggle}>
          <Link className={navStyle.accueil} to="/"><img className={navStyle.logo} src={logo_ugo} alt="logo ugo"/></Link>

          <div className={navStyle.menuburger} onClick={this.displayMenu}>

            <div className={navStyle.bar}></div>
            <div className={navStyle.bar}></div>
            <div className={navStyle.bar}></div>
          </div>

          <div className={navStyle.menu + " " + (this.state.openMenu ? navStyle.show : navStyle.hide)}>
            <div className={navStyle.accueillinks}>
              <div className={navStyle.item}><Link to="/about">{t("about")}</Link></div>
              <div className={navStyle.item}><Link to="/join">{t("join")}</Link></div>
              <div className={navStyle.item}><Link to="/how">{t("how")}</Link></div>
            </div>
            <div className={navStyle.connect}>
              <div className={navStyle.item}> <a className={navStyle.link} href="/app/auth/login">{t("connexion")}</a></div>
              <div className={navStyle.item}> <a className={navStyle.invert} href="/app/auth/registration">{t("inscription")}</a></div>
            </div>
          </div>
        </div>

      </div>

    )
  }

}



export default translate("all")(NavBar)