import React, { Component } from "react"
import classNames from "classnames"
import { translate } from "react-i18next"

import "./footer.module.scss"

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props)
    const { i18n } = this.props
    this.state = { language: i18n.language }

    this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.i18n.language })
  }

  handleChangeLanguage(event) {
    const { i18n } = this.props
    i18n.changeLanguage(event.target.value)
  }

  renderLanguageChoice({ code, label }) {
    const buttonClass = classNames("LanguageSwitcher__button", {
      "LanguageSwitcher__button--selected": this.state.language === code,
    })

    return (
      <option key={code} value={code}>{label}</option>
    )
  }

  render() {
    const languages = [
      { code: "fr-BE", label: "Français" },
      { code: "en-US", label: "English" },
    ]

    return (
      <div className="select">
        <select id="selectbox" onChange={this.handleChangeLanguage} value={this.state.language}>
          {languages.map(language => this.renderLanguageChoice(language))}
        </select>
      </div>
    )
  }
}

export default translate("all")(LanguageSwitcher)